import React, { useRef, useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import logo from "../images/logo.png";
import menuIcon from "../images/menu-icon.png";
import iosIcon from "../images/app_store.png";
import googleIcon from "../images/google_store.png";
import bannerImage from "../images/banner_img.png";

import bannerImage01 from "../images/clothing.png";
import bannerImage02 from "../images/grocery.png";
import bannerImage03 from "../images/salon.png";


import man01 from "../images/man01.png";
import man02 from "../images/man02.png";
import man03 from "../images/man03.png";

import particleOne from "../images/p01.png";
import particleTwo from "../images/p02.png";
import particleThree from "../images/p03.png";
import particleFour from "../images/p04.png";



import Slider from "react-slick";

const Header = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        fade: true,
    };

    return (
        <div className="header_wrapper">

            <div className='header_top_wrapper'>
                <div className='container'>
                    <div className="header_top_wrap">
                        <div className="logo_wrap col-md-2"><img src={logo} /></div>
                        <div className="header_top_right col-lg-7 col-md-9">
                            <div className="main_menu">
                                <span className="toggle-menu">
                                    <img src={menuIcon} />
                                </span>
                                <nav className="nav_sec">
                                    <ul>
                                        <li><a href='#home'>Home</a></li>
                                        <li><a href='#users'>Looklad For Users</a></li>
                                        <li><a href='#business'>Looklad For Business</a></li>
                                        <li><a class="button-5" href='#feedback'>Feedback</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">

                <div className="header_bottom">

                    <div className='header_bottom_left col-sm-12 col-md-6' data-aos="fade-right" data-aos-duration="1500">
                        <h1>LOOKLAD</h1>
                        <h2>Real business search</h2>
                        <p>We are making your local business search better, this time for good.</p>
                        <div className='store_wrap'>
                            <a href='https://apps.apple.com/us/app/id1609028687' target="_blank"><img src={iosIcon} /></a>
                            <a href='https://play.google.com/store/apps/details?id=com.app.looklad'><img src={googleIcon} /></a>
                        </div>
                    </div>
                    <div className='header_bottom_right col-sm-12 col-md-6' data-aos="fade-left" data-aos-duration="1500">

                        <div className='banner_slider'>

                            <div className='banner_slide_box'>
                                <Slider {...settings}>
                                    <div>
                                        <img src={bannerImage01} />
                                    </div>
                                    <div>
                                        <img src={bannerImage02} />
                                    </div>
                                    <div>
                                        <img src={bannerImage03} />
                                    </div>
                                </Slider>
                            </div>

                            <div className='man_one'>
                                <img src={man01} />
                            </div>
                            <div className='man_two'>
                                <img src={man02} />
                            </div>
                            <div className='man_three'>
                                <img src={man03} />
                            </div>

                            <div className='part_01'>
                                <img src={particleOne} />
                            </div>
                            <div className='part_02'>
                                <img src={particleTwo} />
                            </div>
                            <div className='part_03'>
                                <img src={particleThree} />
                            </div>
                            <div className='part_04'>
                                <img src={particleFour} />
                            </div>
                            <div className='part_05'>
                                <img src={particleThree} />
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
};



export default Header;
