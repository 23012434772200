import React, { useRef, useEffect, useState } from 'react';


import { NavLink } from 'react-router-dom';

import footerLogo from "../images/footer_logo.png";
import googleIcon from "../images/google_store.png";
import iosIcon from "../images/app_store.png";


const Footer = () => {




    return (

        <div className="footer_wrap">

            <div className='footer_top'>

                <div className='container'>

                    <div className='footer_logo col-sm-12 col-md-3'>
                        <img src={footerLogo} />
                    </div>

                    <div className='footer_contact_info col-sm-12 col-md-5'>
                        <h3>Contact us</h3>
                        <p className='location_point'>Unispace Business Center, 2nd Avenue, Patrika Nagar, Madhapur, Hyd, 500081.</p>
                        <p className='support_point'><a href='mailto:support@looklad.com'>support@looklad.com</a></p>
                    </div>

                    <div className='footer_community col-sm-12 col-md-4'>
                        <h3>Join the Community</h3>
                        <div className='footer_social'>
                            <ul>
                                <li><a href='https://twitter.com/Looklad_in' target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href='https://www.instagram.com/looklad.in/' target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                <li><a href='https://www.facebook.com/Looklad.in' target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a href='https://www.linkedin.com/company/looklad/' target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                        <div className='store_wrap'>
                            <a href='https://apps.apple.com/us/app/id1609028687' target="_blank"><img src={iosIcon} /></a>
                            <a href='https://play.google.com/store/apps/details?id=com.app.looklad'  target="_blank"><img src={googleIcon} /></a>
                        </div>
                    </div>

                </div>

            </div>

            <div className="footer_bottom">
                <p>© 2023 LookLad. All rights reserved. | <a data-bs-toggle="modal" data-bs-target="#exampleModal" href='#'>Privacy policy</a></p>
            </div>


        </div>
    );
};

export default Footer;
